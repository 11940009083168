import React, { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ReactPlayer from "react-player";
import grandImg from "../assets/images/scp-img-granddesign.webp";
import newImg from "../assets/images/New_build.webp";
import renovationImg from "../assets/images/Extension.webp";
import commercialImg from "../assets/images/scp-img-commercial.webp";
import Ctaform from "../components/CtaForm.jsx";
import TestimonialsSlider from "../components/TestimonialsSlider.jsx";
import Gallery from "../components/Gallery.jsx";
import HelmetTag from "../components/HelmetTag.jsx";
import AllBlog from "../components/AllBlog.jsx";
import bannerVid from "../assets/vid/sb2build.mp4";
import { BsArrowRightShort } from "react-icons/bs";
import { RiDoubleQuotesR } from "react-icons/ri";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { HashLink } from "react-router-hash-link";
import Modal from "react-modal";
import "../assets/css/_modal-styles.scss";
import SlickSlider from "../components/SlickSlider";
import ResidentialServices from "../components/ResidentialServices";
import CommercialServices from "../components/CommercialServices"; // Import the Sass styles

export default function Homepage() {
  document.body.classList.remove(
    "blog",
    "main-blog",
    "single-blog",
    "landing",
    "contact",
    "about",
    "testimonial",
    "enquire",
    "default-page",
    "services"
  );
  document.body.classList.add("home");

  const images = [
    { src: require("../assets/images/gallery/bathroom1.jpeg"), alt: 'Bathroom 1' },
    { src: require("../assets/images/gallery/lounge1.jpg"), alt: 'Lounge 1' },
    { src: require("../assets/images/gallery/residential1.jpg"), alt: 'Residential 1' },
    { src: require("../assets/images/gallery/residential2.jpg"), alt: 'Residential 2' },
    { src: require("../assets/images/gallery/bathroom2.jpg"), alt: 'Bathroom 2' },
    { src: require("../assets/images/gallery/bathroom3.jpg"), alt: 'Bathroom 3' },
    { src: require("../assets/images/gallery/deck1.jpg"), alt: 'Deck 1' },
    { src: require("../assets/images/gallery/deck2.jpg"), alt: 'Deck 2' },
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      width: "50%",
      margin: "auto",
      zIndex: 1000,
      height: "650px",
      overflowY: "scroll", // Set overflow to hidden to remove the scroll
    },
    overlay: {
      zIndex: 999, // Set a high z-index for the overlay
    },
  };

  return (
    <div className="homepage-content">
      <HelmetTag
        pageTitle="Builders Dunedin | House Builders Mosgiel"
        title="Builders Dunedin | House Builders Mosgiel"
        robots="index"
        description="SB2 Build is a certified firm for home building in Dunedin New Zealand. We have an experienced team of home builders in Mosgiel, Otago. Call us: 027 479 7532"
        ogTitle="Builders Dunedin | House Builders Mosgiel"
        ogDescription="SB2 Build is a certified firm for home building in Dunedin New Zealand. We have an experienced team of home builders in Mosgiel, Otago. Call us: 027 479 7532"
        locale="en_NZ"
        type="website"
        updated_time=""
        image=""
        imgsecure_url=""
        imgwidth=""
        imgheight=""
        imgalt=""
        imgtype=""
        artpublished_time=""
        artmodified_time=""
        twcard=""
        twtitle="SB2 Build Ltd | Builders Mosgiel, Dunedin, Otago"
        twdescription="Looking for reputable builders in Dunedin & Mosgiel? SB2Build is a trusted building company offering quality construction services in Otago. Contact us today!"
        twimage=""
        twlabel1=""
        twdata1=""
        twlabel2=""
        twdata2=""
        canonical="https://sb2build.co.nz/"
      />
      <div className="banner dark">
        <div className="banner-content">
          <div className="main-content">
            <h1>Builders Dunedin NZ</h1>
            <h2>Certified House Builders Mosgiel, Dunedin and Otago Wide</h2>
            <div>
              <button
                className="redButton btn"
                onClick={openModal}
                style={{ marginTop: "20px" }}
              >
                Contact Us
              </button>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
                id="custom-modal-id"
              >
                <div className="titleCloseBtn">
                  <button className="modalCloseBtn" onClick={closeModal}>
                    ✖
                  </button>
                </div>
                <div className="section cta" id="contact-us">
                  <div className="container-modal max1000">
                    <div className="text-center">
                      <h2 className="blueText">
                        Make an <span>Enquiry</span>
                      </h2>
                      <p>
                        Please fill in the form and we will be in contact with
                        you within the same business day.
                      </p>
                    </div>
                    <Ctaform />
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
        <ReactPlayer
          className="vid-bg"
          playing={true}
          loop={true}
          muted={true}
          volume={0}
          width="100%"
          height="100%"
          type="video/mp4"
          config={{
            vimeo: {
              playerOptions: {
                loop: true,
                autoplay: true,
                responsive: true,
                controls: false,
                keyboard: false,
                volume: false,
                muted: true,
                playsinline: true,
              },
            },
          }}
          url={bannerVid}
        />
      </div>
      <div className="section info">
        <div className="container max1000" data-aos="fade">
          <h2>Preserving the past and building the future</h2>
          <p>
            Whether you are planning a new build and need{" "}
            <a href="/residential/house-builders-dunedin" title="house-builders-dunedin">
              experienced house builders
            </a>
            , are planning some{" "}
            <a href="/residential/home-renovations-dunedin" title="home renovations">
              home renovations
            </a>{" "}
            or adding that extra extension for the man cave, Mosgiel-based
            builders SB2 Build is your first choice{" "}
            <a
              href="/home-building-companies-dunedin"
              title="building company in Otago"
            >
              building company
            </a>{" "}
            in Otago. Our team also works on large-scale{" "}
            <a href="/commercial-builder-dunedin" title="commercial building">
              commercial building
            </a>{" "}
            projects including{" "}
            <a
              href="/commercial/structural-upgrades-dunedin"
              title="Structural Upgrades for Homes and Commercial Establishments"
            >
              seismic strengthening for existing buildings
            </a>
            . Whatever your project, SB2 Build offers you value for money, full
            project management and a high standard of finish.
            <br />
            SB2 Build were proud to be the builders showcased on{" "}
            <a href="/residential/build-grand-designs" title="Grand Designs NZ">
              Grand Designs
            </a>{" "}
            NZ in November 2020. The large scale heritage project was a
            combination of all their skills to the highest of standards.
            <br />
            If you are wanting to chat about your design ideas, contact the team
            at SB2 Build
          </p>
          <HashLink smooth to="#contact-us" className="redButton btn">
            {" "}
            Contact Us Now{" "}
          </HashLink>
        </div>
      </div>
      <div className="section builds gray">
        <div className="container">
          <h2 className="center">
            Residential <span>Services</span>
          </h2>
          <ResidentialServices></ResidentialServices>
          <h2 className="center">
            Commercial <span>Services</span>
          </h2>
          <CommercialServices></CommercialServices>
          <HashLink smooth to="#contact-us" className="redButton btn center">
            {" "}
            Enquire Now{" "}
          </HashLink>
        </div>
      </div>
      <div className="section gallery">
        <div className="container fullwidth no-padd">
          <SlickSlider images={images} />
        </div>
      </div>
      <div className="section latest-blog">
        <div className="container">
          <h2 className="center blueText">
            Latest <span>Blog</span>
          </h2>
          <AllBlog categoryFilter="all" limit={2} />
        </div>
      </div>
      <div className="section testimonial darkblue">
        <div className="container center">
          <h2 className="center">Testimonials</h2>
          <RiDoubleQuotesR />
          <TestimonialsSlider showingPost={"limited"} />
        </div>
      </div>
      <div className="section cta" id="contact-us">
        <div className="container max1000">
          <h2 className="blueText">
            Make an <span>Enquiry</span>
          </h2>
          <p>
            Please fill in the form and we will be in contact with you within
            the same business day.
          </p>
          <Ctaform />
        </div>
      </div>
    </div>
  );
}
