import React from "react";
import { Link } from "react-router-dom";
import HelmetTag from "../components/HelmetTag.jsx";
import Banner from "../components/Banner.jsx";
import AllBlog from "../components/AllBlog.jsx";
import Ctaform from "../components/CtaForm.jsx";
import { BsArrowRightShort,BsCheckCircleFill } from "react-icons/bs";
import bannerBg from "../assets/images/commercial.webp";
import img1 from "../assets/images/empire-hotel.webp"
import img2 from "../assets/images/seismic-strengthening.webp"
import img3 from "../assets/images/commercial-builder.webp"
import PhotoGallery from "../components/PhotoGallery";
import Grid from "@mui/material/Grid";
import {LazyLoadImage} from "react-lazy-load-image-component";
import grandImg from "../assets/images/scp-img-granddesign.webp";
import newImg from "../assets/images/New_build.webp";
import renovationImg from "../assets/images/Extension.webp";
import CommercialServices from "../components/CommercialServices";

const Commercial = () => {
    document.body.classList.remove(
      "blog",
      "main-blog",
      "single-blog",
      "home",
      "contact",
      "about",
      "testimonial",
      "enquire",
      "default-page"
    );
    document.body.classList.add("landing");
    const canonicalUrl = window.location.href;

    return (
      <div className="grand-content landing">
        <HelmetTag 
            pageTitle="Commercial Building Services Dunedin | SB2 Build"
            title="Commercial Building Services Dunedin | SB2 Build"
            robots="index"
            description="Top commercial builders in Dunedin, delivering expert construction services. Rely on us for exceptional results in your commercial projects."
            ogTitle="Commercial Building Services Dunedin | SB2 Build"
            ogDescription="Top commercial builders in Dunedin, delivering expert construction services. Rely on us for exceptional results in your commercial projects."
            locale="en_NZ"
            type="website"
            updated_time=""
            image=""
            imgsecure_url=""
            imgwidth=""
            imgheight=""
            imgalt=""
            imgtype=""
            artpublished_time=""
            artmodified_time=""
            twcard=""
            twtitle="Commercial Building Services Dunedin | SB2 Build"
            twdescription="Top commercial builders in Dunedin, delivering expert construction services. Rely on us for exceptional results in your commercial projects."
            twimage=""
            twlabel1=""
            twdata1=""
            twlabel2=""
            twdata2=""
            canonical={canonicalUrl}
        
        />
        <Banner
          header = "Our Commercial Services"
          imgBg = {bannerBg}
          pageName = "Commercial"
          pageLink = "/commercial-builder-dunedin"
        />
          <CommercialServices></CommercialServices>

          <PhotoGallery></PhotoGallery>

        <div className="section latest-blog gray">
          <div className="container">
            <h2 className="center blueText">Latest <span>Blog</span></h2>
            <AllBlog categoryFilter="all" limit={2} />
          </div>
        </div>
        <div className="section cta">
          <div className="container max1000">
            <h2 className="blueText">Make an <span>Enquiry</span></h2>
            <p>Please fill in the form and we will be in contact with you within the same business day.</p>
            <Ctaform />
          </div>
        </div>
      </div>
    );
  }

  export default Commercial
