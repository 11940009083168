import {React} from 'react';
import HelmetTag from '../components/HelmetTag';
import Banner from '../components/Banner';
import AllTestimonials from "../components/AllTestimonials.jsx";
import bannerBg from "../assets/images/sb2-form-bg.webp";
import Ctaform from '../components/CtaForm';
function TestimonialPage() {
    document.body.classList.remove(
    "single-blog",
    "blog",
    "main-blog",
    "home",
    "landing",
    "contact",
    "about",
    "enquire",
    "default-page"
    );
    document.body.classList.add('testimonial');
  return (
    <div className="testimonial landing">
         <HelmetTag 
           pageTitle="Top Rated Builder in Dunedin & Mosgiel | SB2 Build"
           title="Top Rated Builder in Dunedin & Mosgiel | SB2 Build"
           robots="index"
           description="Looking for a trusted builder in Dunedin? SB2 Build offers expert craftsmanship for home builds, renovations, and extensions. Contact us today!"
           ogTitle="Top Rated Builder in Dunedin & Mosgiel | SB2 Build"
           ogDescription="Looking for a trusted builder in Dunedin? SB2 Build offers expert craftsmanship for home builds, renovations, and extensions. Contact us today!"
            locale=""
            type=""
            updated_time=""
            image=""
            imgsecure_url=""
            imgwidth=""
            imgheight=""
            imgalt=""
            imgtype=""
            artpublished_time=""
            artmodified_time=""
            twcard=""
            twtitle="Top Rated Builder in Dunedin & Mosgiel | SB2 Build"
            twdescription="Looking for a trusted builder in Dunedin? SB2 Build offers expert craftsmanship for home builds, renovations, and extensions. Contact us today!"
            twimage=""
            twlabel1=""
            twdata1=""
            twlabel2=""
            twdata2=""
            canonical="https://sb2build.co.nz/recommended-builder-dunedin"
        />
        <Banner
          header = "Testimonials"
          imgBg = {bannerBg}
          pageName = "Testimonials"
          pageLink = "/recommended-builder-dunedin"
        />
        <div className="section content">
            <div className="container">
                <AllTestimonials />
            </div>
        </div>
        <div className="section cta gray">
          <div className="container max1000">
            <h2 className="blueText">Make an <span>Enquiry</span></h2>
            <p>Please fill in the form and we will be in contact with you within the same business day.</p>
            <Ctaform />
          </div>
        </div>
    </div>
  )
}

export default TestimonialPage
